import * as Cookies from 'js-cookie';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    toast,
    isFeatureAllowed,
    capitalizeFirstLetter,
    capitaliseFirst
} from '~/services/utilityFunctions';
import {
    PERMISSIONS,
    ACQUISITION_AUTH_INTERVALS,
    PREMIUM_PLANS,
    PLATFORM_FEATURES
} from '~/static/constants.js';
import * as AUTH_FLOWS from '~/constants/authFlows.js';
import { LOGIN_METHOD } from '~/constants/authFlows.js';
import { trackLogout } from '~/services/analyticsTracker';
import { ROUTES } from '~/constants/pageRoutes';
dayjs.extend(utc);

export const state = () => ({
    // login/signup
    authFlow: AUTH_FLOWS.INDIAN_PHONE.authFlow,
    authSteps: AUTH_FLOWS.INDIAN_PHONE.authSteps,
    onBoardingStep: AUTH_FLOWS.INDIAN_PHONE[0],
    loginMethod: LOGIN_METHOD.PHONE,
    candidateCountryCode: 91,
    verifyUuid: '',
    googleLoginCredential: null,

    // Below fields used ONLY during onboarding
    candidatePhone: '',
    candidateFirstName: '',
    candidateLastName: '',
    candidateEmail: '',
    // Below field holds the exam related data for exam types - GRE,GMAT
    candidateExamType: null,
    candidateWorkStatus: null,
    candidateExamAttemptStage: null,
    candidateExamRegistrationStatus: null,
    candidateExamDate: null,

    errorEmailMessage: '',
    errorPhoneMessage: '',
    errorFirstNameMessage: '',
    errorLastNameMessage: '',

    registerLoading: false,
    currentTab: 'login',
    otpLoading: false,
    countries: [],
    selectedCountryCode: {
        abbreviation: 'IN',
        calling_code: 91
    },
    userCountry: 'IN',
    continueRoute: '',
    signupHook: '',

    // user details to be used everywhere else
    user: null, // user details object
    profileCompletionLevel: 0,
    hasGivenQuickDiagnostic: false,
    hasGivenFullDiagnostic: false,
    isDashboardSetup: false,
    recentQuickDiagId: null,
    recentFullDiagId: null,
    isPremiumUser: false,
    fetchingUser: true,
    isPlannerSetup: false,
    chatBotAgreeStatus: true,
    chatBotConsentStatus: false,
    userExtraDetails: null,
    subscriptionDetails: null,
    featurePermissions: [],
    userActivities: [],
    freeTrialStatus: { activated: false, expired: false, remainingTime: null },
    freeTrialEndTime: null,
    plannerVersion: null,
    userPremiumFeatures: [],
    liveTestTaken: false,
    hasPrepFreebies: false,

    // Acquisition modal handling
    timePassedSinceBlogsVisit: 0,
    acquisitionAuthTimer: null,
    showTimerBasedAuthModal: false
});

export const mutations = {
    setAuthFlow(state, { isInternationalUser, loginMethod }) {
        switch (loginMethod) {
            case LOGIN_METHOD.PHONE:
                if (isInternationalUser) {
                    state.authFlow = AUTH_FLOWS.INTERNATIONAL_PHONE.authFlow;
                    state.authSteps = AUTH_FLOWS.INTERNATIONAL_PHONE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                } else {
                    state.authFlow = AUTH_FLOWS.INDIAN_PHONE.authFlow;
                    state.authSteps = AUTH_FLOWS.INDIAN_PHONE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                }
                break;

            case LOGIN_METHOD.EMAIL:
                if (isInternationalUser) {
                    state.authFlow = AUTH_FLOWS.INTERNATIONAL_EMAIL.authFlow;
                    state.authSteps = AUTH_FLOWS.INTERNATIONAL_EMAIL.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.EMAIL;
                } else {
                    state.authFlow = AUTH_FLOWS.INDIAN_PHONE.authFlow;
                    state.authSteps = AUTH_FLOWS.INDIAN_PHONE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                }
                break;

            case LOGIN_METHOD.SOCIAL:
                if (isInternationalUser) {
                    state.authFlow = AUTH_FLOWS.INTERNATIONAL_GOOGLE.authFlow;
                    state.authSteps = AUTH_FLOWS.INTERNATIONAL_GOOGLE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                } else {
                    state.authFlow = AUTH_FLOWS.INDIAN_GOOGLE.authFlow;
                    state.authSteps = AUTH_FLOWS.INDIAN_GOOGLE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                }
                break;

            default:
                if (isInternationalUser) {
                    state.authFlow = AUTH_FLOWS.INTERNATIONAL_EMAIL.authFlow;
                    state.authSteps = AUTH_FLOWS.INTERNATIONAL_EMAIL.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.EMAIL;
                } else {
                    state.authFlow = AUTH_FLOWS.INDIAN_PHONE.authFlow;
                    state.authSteps = AUTH_FLOWS.INDIAN_PHONE.authSteps;
                    state.loginMethod = AUTH_FLOWS.LOGIN_METHOD.PHONE;
                }
        }

        state.onBoardingStep = state.authSteps[0];
    },
    setChatBotAgreeStatus(state, payload) {
        state.chatBotAgreeStatus = payload;
    },
    setChatBotConsentStatus(state, payload) {
        state.chatBotConsentStatus = payload;
    },
    setGoogleLoginCredential(state, value) {
        state.googleLoginCredential = value;
    },
    setVerifyUuid(state, value) {
        state.verifyUuid = value;
    },
    setLoginMethod(state, value) {
        state.loginMethod = value;
    },
    setCandidateFirstName(state, value) {
        state.candidateFirstName = value;
    },
    setCandidateLastName(state, value) {
        state.candidateLastName = value;
    },
    setCandidatePhone(state, value) {
        state.candidatePhone = value;
    },
    setCandidateCountryCode(state, value) {
        state.candidateCountryCode = value;
    },
    setCandidateEmail(state, value) {
        state.candidateEmail = value;
    },
    setCandidateExamType(state, value) {
        state.candidateExamType = value;
    },
    setCandidateWorkStatus(state, value) {
        state.candidateWorkStatus = value;
    },
    setCandidateExamAttemptStage(state, value) {
        state.candidateExamAttemptStage = value;
    },
    setCandidateHasRegisteredForExam(state, value) {
        state.candidateHasRegisteredForExam = value;
    },
    setCandidateExamRegistrationStatus(state, value) {
        state.candidateExamRegistrationStatus = value;
    },
    setCandidateExamDate(state, value) {
        state.candidateExamDate = value;
    },
    resetOnboardingDetails(state) {
        state.onBoardingStep = state.authSteps[0];
        state.candidatePhone = '';
        state.candidateFirstName = '';
        state.candidateLastName = '';
        state.candidateEmail = '';
        state.candidateExamType = null;
        state.candidateWorkStatus = null;
        state.candidateExamAttemptStage = null;
        state.candidateExamRegistrationStatus = null;
        state.candidateExamDate = null;
        state.errorEmailMessage = '';
        state.errorPhoneMessage = '';
    },
    resetOnboardingStep(state) {
        state.onBoardingStep = state.authSteps[0];
    },
    setNextOnboardingStep(state) {
        state.onBoardingStep = state.authSteps[state.onBoardingStep.stepCount + 1];
    },
    setPreviousOnboardingStep(state) {
        state.onBoardingStep = state.authSteps[state.onBoardingStep.stepCount - 1];
    },
    setErrorFirstNameMessage(state, value) {
        state.errorFirstNameMessage = value;
    },
    setErrorLastNameMessage(state, value) {
        state.errorLastNameMessage = value;
    },
    setErrorEmailMessage(state, value) {
        state.errorEmailMessage = value;
    },
    setErrorPhoneMessage(state, value) {
        state.errorPhoneMessage = value;
    },
    setRegisterLoading(state, value) {
        state.registerLoading = value;
    },
    setOtpLoading(state, value) {
        state.otpLoading = value;
    },
    setCandidateSelectedCountryCode(state, value) {
        state.candidateSelectedCountryCode = value;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    setProfileCompletionLevel(state, value) {
        state.profileCompletionLevel = value;
    },
    setHasGivenQuickDiagnostic(state, payload) {
        state.hasGivenQuickDiagnostic = payload;
    },
    setHasGivenFullDiagnostic(state, payload) {
        state.hasGivenFullDiagnostic = payload;
    },
    setFetchingUser(state, payload) {
        state.fetchingUser = payload;
    },
    setIsDashboardSetup(state, value) {
        state.isDashboardSetup = value;
    },
    setRecentQuickDiagId(state, value) {
        state.recentQuickDiagId = value;
    },
    setRecentFullDiagId(state, value) {
        state.recentFullDiagId = value;
    },
    setIsPlannerSetup(state, value) {
        state.isPlannerSetup = value;
    },
    setIsPremiumUser(state, value) {
        state.isPremiumUser = value;
    },
    setContinueRoute(state, value) {
        state.continueRoute = value;
    },
    setCountries(state, value) {
        state.countries = value;
    },
    setSelectedCountryCode(state, value) {
        state.selectedCountryCode = value;
    },
    setUserCountry(state, value) {
        state.userCountry = value;
    },
    setSubscriptionDetails(state, value) {
        state.subscriptionDetails = value;
    },
    setFeaturePermissions(state, value) {
        state.featurePermissions = value;
    },
    setUserActivities(state, value) {
        state.userActivities = value;
    },
    addUserActivity(state, value) {
        const activity = state.userActivities.find(
            (activity) => activity.action_id === value.action_id
        );
        if (!activity) state.userActivities = [...state.userActivities, value];
    },
    updateUserActivity(state, { actionId, statusId }) {
        const activity = state.userActivities.find((activity) => activity.action_id === actionId);
        activity.status_id = statusId;
    },
    setUserExtraDetails(state, payload) {
        state.userExtraDetails = payload;
    },
    updateUserExtraDetails(state, payload) {
        if (!state.userExtraDetails) state.userExtraDetails = {};

        for (const [key, value] of Object.entries(payload)) {
            state.userExtraDetails[key] = value;
        }
    },
    setFreeTrialEndTime(state, payload) {
        state.freeTrialEndTime = payload;
    },
    setFreeTrialData(state, isExpired = false) {
        // endDate = null => for existing users since trial won't be activated for them
        if (!state.freeTrialEndTime || state.isPremiumUser) {
            state.freeTrialStatus.activated = false;
        } else {
            state.freeTrialStatus.activated = true;

            if (isExpired) {
                state.freeTrialStatus.expired = true;
                return;
            }

            const today = dayjs().utc();
            const validTill = dayjs(state.freeTrialEndTime).utc();
            const diffInMinutes = validTill.diff(today, 'minutes');

            if (diffInMinutes <= 0) {
                state.freeTrialStatus.expired = true;
                return;
            }

            const days = Math.floor(diffInMinutes / 60 / 24);
            const hours = String(Math.floor(diffInMinutes / 60) % 24).padStart(2, '0');
            const minutes = String(diffInMinutes % 60).padStart(2, '0');

            state.freeTrialStatus.remainingTime = { days, hours, minutes };
        }
    },
    setPlannerVersion(state, value) {
        state.plannerVersion = value;
    },
    setUserPremiumFeatures(state, payload) {
        state.userPremiumFeatures = payload;
    },
    setLiveTestTaken(state, data) {
        state.liveTestTaken = data;
    },
    setAcquisitionAuthTimer(state, value) {
        state.acquisitionAuthTimer = value;
    },
    clearAcquisitionAuthTimer(state, payload) {
        // If blogs page is being left and the auth modal WAS triggered once,
        // don't clear anything and let the modal show for the second time throughout the app.
        if (
            payload &&
            payload.leavingBlogsPage &&
            state.timePassedSinceBlogsVisit > ACQUISITION_AUTH_INTERVALS.FIRST_INTERVAL
        ) {
            return;
        }

        state.timePassedSinceBlogsVisit = 0;
        clearInterval(state.acquisitionAuthTimer);
        state.acquisitionAuthTimer = null;
        window.localStorage.removeItem('acq_time');

        if (payload && payload.hideModal) {
            state.showTimerBasedAuthModal = false;
        }
    },
    updateTimePassedSinceBlogsVisit(state, value) {
        state.timePassedSinceBlogsVisit = value;
    },
    toggleTimerBasedAcqAuthModal(state, value) {
        state.showTimerBasedAuthModal = value;
    },
    setHasPrepFreebies(state, value) {
        state.hasPrepFreebies = value;
    },
    setSignupHook(state, value) {
        state.signupHook = value;
    }
};

export const actions = {
    async getLoggedInUser({ state, commit, dispatch }) {
        try {
            state.userLoading = true;
            const {
                data: { data }
            } = await this.$api.users.getLoggedInUser();

            // If no details are filled and cookie is present due to verify otp stage previously,
            // delete the cookie and mark logout
            if (data.profile_completion_level === 0) {
                await this.$api.auth.logout();
                commit('setUser', {});
            }

            if (
                this.$device.isMobileOrTablet &&
                !data.is_premium_user &&
                isFeatureAllowed(PLATFORM_FEATURES.PRICING, data.feature_permissions)
            ) {
                await this.dispatch('premium/getPricingPageData');
            }

            // User data
            data.user.first_name = capitalizeFirstLetter(data.user.first_name);
            data.user.last_name = capitalizeFirstLetter(data.user.last_name);
            data.user.name = capitaliseFirst(data.user.name);

            commit('setUser', data.user);
            commit('setProfileCompletionLevel', data.profile_completion_level);
            commit('setUserExtraDetails', data.user_extra_details);
            commit('setUserActivities', data.user_activities);

            if (data.user.country_code && data.user.country_code !== 91) {
                commit('setUserCountry', 'US'); // Any non-indian country to identify them as international user
            }

            // Premium data
            commit('setIsPremiumUser', data.is_premium_user);
            commit('setSubscriptionDetails', data.subscription);
            commit('setUserPremiumFeatures', data.user_premium_features);

            // Section Permissions data
            commit('setFeaturePermissions', data.feature_permissions);

            if (isFeatureAllowed(PLATFORM_FEATURES.DASHBOARD, data.feature_permissions)) {
                // Test data
                commit(
                    'setHasGivenQuickDiagnostic',
                    data.dashboard_snapshot.recent_diag.QD.hasGiven
                );
                commit(
                    'setHasGivenFullDiagnostic',
                    data.dashboard_snapshot.recent_diag.FD.hasGiven
                );
                commit('setIsDashboardSetup', data.dashboard_snapshot.is_dashboard_setup);
                commit(
                    'setRecentQuickDiagId',
                    data.dashboard_snapshot.recent_diag.QD.test_taker_id
                );
                commit('setRecentFullDiagId', data.dashboard_snapshot.recent_diag.FD.test_taker_id);

                // Freebies
                commit('setHasPrepFreebies', data.user.has_prep_freebies);

                commit('setLiveTestTaken', data.has_user_completed_gre_live_test);
            }

            if (isFeatureAllowed(PLATFORM_FEATURES.STUDY_PLANNER, data.feature_permissions)) {
                // Planner data
                commit('setIsPlannerSetup', data.is_planner_setup);
                commit('setPlannerVersion', data.planner_version);
            }
            // On mobile device setup default dashboard by calling api and mark the setup as true manually
            if (
                !data.dashboard_snapshot.recent_diag.QD.hasGiven &&
                this.$device.isMobileOrTablet &&
                isFeatureAllowed(PLATFORM_FEATURES.DASHBOARD, data.feature_permissions)
            ) {
                // Action dispatch to fetch default dashboard
                await dispatch('dashboard/getDashboardData', {}, { root: true });
            }

            if (isFeatureAllowed(PLATFORM_FEATURES.FREE_TRIAL, data.feature_permissions)) {
                commit('setFreeTrialEndTime', data.user.prep_free_trial_ends_at);
                commit('setFreeTrialData', data.user.has_limited_free_access_expired);
            }
            // set user_id = user's uuid in GA
            const userUUid = data.user.uuid;
            if (process.env.SERVER_ENV === 'prod') {
                window.gtag('config', process.env.GA_TRACKING_ID, { user_id: userUUid });
            }
            // Set clarity fields
            if (window.clarity && data.user) {
                window.clarity('set', 'role_id', data.user.role_id.toString());
                window.clarity('identify', userUUid);
            }

            // Reddit Pixel init with email
            // if(window.rdt && data.user?.primary_email) {
            //     window.rdt('init', `${process.env.REDDIT_PIXEL_TRACKING_ID}`, {
            //         email: data.user.primary_email
            //     });
            // }
        } catch (err) {
            if (this.$device.isMobileOrTablet) {
                await this.dispatch('premium/getPricingPageData');
            }
        } finally {
            commit('setFetchingUser', false);
        }
    },

    async logout({ commit }) {
        // Call the api.
        // Set ENV-testprep-logout cookie to true. It denotes that account is manually logged out.
        // Clear the user data. It will set auth status to false.
        commit('setFetchingUser', true);
        try {
            await this.$api.auth.logout();
            await Cookies.set(`${process.env.SERVER_ENV}-testprep-logout`, 'true', {
                domain: process.env.DOMAIN
            });

            commit('setUser', {});
            commit('setIsPlannerSetup', false);

            trackLogout();
            window.location.href = ROUTES.HOME;

            // Remove session data
            sessionStorage.removeItem('plans-query-call-booked');

            // Remove dashboard banner dismissed cookie
            Cookies.remove('pl-udb-vb', { domain: process.env.DOMAIN });

            commit('clearAcquisitionAuthTimer');
            sessionStorage.removeItem('acq_auth_shown');
        } catch (error) {
            const toastPayload = {
                message: 'Something went wrong while signing up! Please try again.'
            };
            toast.failureToast(toastPayload);
        }
        // commit('setFetchingUser', false)
    },

    async fetchListOfCountries({ commit }) {
        const response = { state: false };

        try {
            const {
                data: { state, data }
            } = await this.$api.auth.getCountriesList();

            if (state && data.countries.length) {
                commit('setCountries', data.countries);

                if (data.default_country) {
                    const defaultCountryCode = data.countries.find(
                        (country) => country.abbreviation === data.default_country
                    );

                    // eslint-disable-next-line max-depth
                    if (defaultCountryCode) {
                        commit('setSelectedCountryCode', defaultCountryCode);
                    }
                }
            }

            response.state = state;
        } catch (error) {
            response.state = false;
        }

        return response;
    },

    async getIpCountry({ state, commit }) {
        try {
            const {
                data: { state, data }
            } = await this.$api.utils.getIpCountry();

            if (state) {
                commit('setUserCountry', data.abbreviation);
                if (data.abbreviation !== 'IN') {
                    commit('setLoginMethod', LOGIN_METHOD.EMAIL);
                }
            }
        } catch (error) {
            commit('setUserCountry', 'IN');
        } finally {
            commit('setAuthFlow', {
                isInternationalUser: state.userCountry !== 'IN',
                loginMethod: state.loginMethod
            });
        }
    },

    startAcquisitionAuthTimer({ state, commit, getters }) {
        if (state.acquisitionAuthTimer != null) {
            return;
        }

        // If logged in OR acquisition auth flow is already shown 2 times. Don't start the timer anymore.
        if (getters.isAuthenticated || sessionStorage.getItem('acq_auth_shown')) {
            commit('clearAcquisitionAuthTimer');
            commit('toggleTimerBasedAcqAuthModal', false);
            return;
        }

        const blogsVisitTimestampString = window.localStorage.getItem('acq_time');

        // If page is refreshed, fetch the timestamp stored in localstorage which stores when blogs was visited first.
        if (blogsVisitTimestampString) {
            const blogsVisitTimestamp = new Date(parseInt(blogsVisitTimestampString)).getTime();
            const now = Date.now();
            const timeDiff = Math.floor((now - blogsVisitTimestamp) / 1000);

            commit('updateTimePassedSinceBlogsVisit', timeDiff);
        } else {
            window.localStorage.setItem('acq_time', Date.now());
            commit('updateTimePassedSinceBlogsVisit', 0);
        }

        const timer = setInterval(() => {
            commit('updateTimePassedSinceBlogsVisit', state.timePassedSinceBlogsVisit + 1);

            if (
                state.timePassedSinceBlogsVisit === ACQUISITION_AUTH_INTERVALS.FIRST_INTERVAL ||
                state.timePassedSinceBlogsVisit === ACQUISITION_AUTH_INTERVALS.SECOND_INTERVAL
            ) {
                commit('toggleTimerBasedAcqAuthModal', true);
            }

            if (state.timePassedSinceBlogsVisit === ACQUISITION_AUTH_INTERVALS.SECOND_INTERVAL) {
                commit('clearAcquisitionAuthTimer');
                sessionStorage.setItem('acq_auth_shown', true);
                // also set a session key value pair so that it is not restarted when land on blogs page again
            }
        }, 1000);

        commit('setAcquisitionAuthTimer', timer);
    }
};

export const getters = {
    showAcquisitionAuthModal(state, getters) {
        // Show modal either if time interval triggers it (30s and 120s intervals)
        // Or show permanently after second time
        if (process.client) {
            const showNonDismissableModal = window.sessionStorage.getItem('acq_auth_shown');
            return (
                (state.showTimerBasedAuthModal || showNonDismissableModal) &&
                !getters.isAuthenticated
            );
        }

        return false;
    },
    loginMethod(state) {
        return state.loginMethod;
    },
    signupHook(state) {
        return state.signupHook;
    },
    showYocketBanner(state) {
        if (state.user) {
            if (state.user.country_code === 91 && state.user.signup_platform_id === 2) {
                return true;
            }
        } else if (state.userCountry === 'IN') {
            return true;
        }

        return false;
    },
    getUser(state) {
        return state.user;
    },
    testType(state) {
        return state.userExtraDetails.test_key;
    },
    isAuthenticated(state) {
        return Boolean(state.profileCompletionLevel >= 1 && Object.keys(state.user).length);
    },
    profileCompletionLevel(state) {
        return state.profileCompletionLevel;
    },
    fetchingUser(state) {
        return state.fetchingUser;
    },
    hasGivenQuickDiagnostic(state) {
        return state.user && state.hasGivenQuickDiagnostic;
    },
    hasGivenFullDiagnostic(state) {
        return state.user && state.hasGivenFullDiagnostic;
    },
    isDashboardSetup(state) {
        return state.isDashboardSetup;
    },
    recentQuickDiagId(state) {
        return state.recentQuickDiagId;
    },
    recentFullDiagId(state) {
        return state.recentFullDiagId;
    },
    isPlannerSetup(state) {
        return state.isPlannerSetup;
    },
    isPremiumUser(state) {
        return state.isPremiumUser;
    },
    getChatBotAgreeStatus(state) {
        return state.chatBotAgreeStatus;
    },
    getChatBotConsentStatus(state) {
        return state.chatBotConsentStatus;
    },
    continueRoute(state) {
        return state.continueRoute;
    },
    getCountries(state) {
        return state.countries;
    },
    getSelectedCountryCode(state) {
        return state.selectedCountryCode;
    },
    userCountry(state) {
        return state.userCountry;
    },
    showPlanExpiryOverlay(state) {
        return (
            state.subscriptionDetails?.status === 'EXPIRED' &&
            !state.subscriptionDetails?.continue_without_prep_plus
        );
    },
    isPlanExpired(state) {
        return state.subscriptionDetails?.status === 'EXPIRED';
    },
    getUserActivities(state) {
        return state.userActivities;
    },
    userExtraDetails(state) {
        return state.userExtraDetails;
    },
    freeTrialStatus(state) {
        // return { activated: false, expired: false, remainingTime:null };
        return state.freeTrialStatus;
    },
    isFreeTrialExpired(state) {
        return (
            !state.isPremiumUser && state.freeTrialStatus.activated && state.freeTrialStatus.expired
        );
    },
    getPlannerVersion(state) {
        return parseInt(state.plannerVersion);
    },
    getUserPremiumFeatures(state) {
        return state.userPremiumFeatures;
    },
    featurePermissions(state) {
        return state.featurePermissions;
    },
    liveClassAccess(state) {
        return isFeatureAllowed(PERMISSIONS.GRE_LIVE_CLASS_ACCESS, state.userPremiumFeatures);
    },
    selfPacedCourseAccess(state, getters) {
        return (
            isFeatureAllowed(PERMISSIONS.GRE_SELF_PACED_COURSE, state.userPremiumFeatures) ||
            getters.isOnDemandTrialActive
        );
    },
    isClassesAccessible(state) {
        const liveClassAccess = isFeatureAllowed(
            PERMISSIONS.GRE_LIVE_CLASS_ACCESS,
            state.userPremiumFeatures
        );

        const selfPacedCourseAccess = isFeatureAllowed(
            PERMISSIONS.GRE_SELF_PACED_COURSE,
            state.userPremiumFeatures
        );

        return liveClassAccess || selfPacedCourseAccess;
    },
    isLiveClassUpgradeAvailable(state) {
        const liveClassAccess = isFeatureAllowed(
            PERMISSIONS.GRE_LIVE_CLASS_ACCESS,
            state.userPremiumFeatures
        );

        const selfPacedCourseAccess = isFeatureAllowed(
            PERMISSIONS.GRE_SELF_PACED_COURSE,
            state.userPremiumFeatures
        );

        // Upgrade available only to Indian users
        return (
            state.userCountry === 'IN' &&
            state.isPremiumUser &&
            !liveClassAccess &&
            !selfPacedCourseAccess
        );
    },
    liveTestTaken(state) {
        return state.liveTestTaken;
    },
    isLearningAidAccessible(state) {
        return isFeatureAllowed(PERMISSIONS.GRE_PLANNER_LEARNING_AID, state.userPremiumFeatures);
    },
    hasPrepFreebies(state) {
        return state.hasPrepFreebies;
    },
    currentActivePlan(state) {
        if (state.subscriptionDetails && state.subscriptionDetails.status === 'ACTIVE')
            return state.subscriptionDetails.plan_slug;

        return null;
    },
    isOnDemandUpgradeable(state, getters) {
        return (
            getters.currentActivePlan &&
            [
                PREMIUM_PLANS.PREP_PLUS_1_MONTH,
                PREMIUM_PLANS.PREP_PLUS_3_MONTH,
                PREMIUM_PLANS.PREP_PLUS_6_MONTH
            ].includes(getters.currentActivePlan)
        );
    },
    isOnDemandTrialActive(state) {
        return (
            !state.isPremiumUser &&
            state.freeTrialStatus.activated &&
            !state.freeTrialStatus.expired
        );
    }
};
